@tailwind base;
@tailwind components;
@tailwind utilities;

.prose pre {
  margin: 0;
  padding: 0;
}

.prose pre div {
  margin: 0 !important;
}

.prose pre code {
  padding: 0;
}

.prose h1 {
  @apply mb-0 border-b pb-4 border-neutral-600;
}

.prose h2 {
  @apply mb-4 border-b pb-4 border-neutral-600;
}
